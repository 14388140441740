import React, { useState } from 'react'
import BigNumber from 'bignumber.js'
import { useTranslation } from 'contexts/Localization'
import { CardFooter } from '@pancakeswap/uikit'
import { DeserializedPool } from 'state/types'
import ExpandedFooter from './ExpandedFooter'

interface FooterProps {
  pool: DeserializedPool
  account: string
  totalCakeInVault?: BigNumber
  defaultExpanded?: boolean
}

const Footer: React.FC<FooterProps> = ({ pool, account, defaultExpanded }) => {
  const { t } = useTranslation()

  return (
    <CardFooter>
      <ExpandedFooter pool={pool} account={account} />
    </CardFooter>
  )
}

export default Footer
