import React, { useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import { useWeb3React } from '@web3-react/core'
import { Heading, TelegramIcon, Flex, darkColors, Link, Skeleton, TwitterIcon, GithubIcon } from '@pancakeswap/uikit'
import partition from 'lodash/partition'
import { useTranslation } from 'contexts/Localization'
import { HEARN_REFERRAL_KEY } from 'config'
import useParsedQueryString from 'hooks/useParsedQueryString'
import useIntersectionObserver from 'hooks/useIntersectionObserver'
import {
  useFetchPublicPoolsData,
  useFetchUserPools,
  usePools,
} from 'state/pools/hooks'
import FlexLayout from 'components/Layout/Flex'
import Page from 'components/Layout/Page'
import PageHeader from 'components/PageHeader'
import { usePoolsWithVault } from 'views/Home/hooks/useGetTopPoolsByApr'
import PoolCard from './components/PoolCard'
import RoiCalculatorCard from './components/RoiCalculatorCard'
import ReferralCard from './components/ReferralCard'
import WalletMenu from './components/WalletMenu'
import GenericStatsRow from './components/StatsSection/GenericStats'


const CardLayout = styled(FlexLayout)`
  justify-content: center;
`

const StyledImage = styled.img`
  width: 240px;
  height: auto;
  margin-bottom: 36px;
`

const NUMBER_OF_POOLS_VISIBLE = 12

const Pools: React.FC = () => {
  const { t } = useTranslation()
  const { account } = useWeb3React()
  const [numberOfPoolsVisible, setNumberOfPoolsVisible] = useState(NUMBER_OF_POOLS_VISIBLE)
  const { observerRef, isIntersecting } = useIntersectionObserver()
  const chosenPoolsLength = useRef(0)

  const parsedQs = useParsedQueryString()
  useEffect(() => {
    if (parsedQs && parsedQs.ref) {
      localStorage.setItem(HEARN_REFERRAL_KEY, parsedQs.ref.toString())
    }
  }, [parsedQs])

  // const pools = usePoolsWithVault()
  const { pools } = usePools()

  // TODO aren't arrays in dep array checked just by reference, i.e. it will rerender every time reference changes?
  const [finishedPools, chosenPools] = useMemo(() => partition(pools, (pool) => pool.isFinished), [pools])

  useFetchPublicPoolsData()
  useFetchUserPools(account)

  useEffect(() => {
    if (isIntersecting) {
      setNumberOfPoolsVisible((poolsCurrentlyVisible) => {
        if (poolsCurrentlyVisible <= chosenPoolsLength.current) {
          return poolsCurrentlyVisible + NUMBER_OF_POOLS_VISIBLE
        }
        return poolsCurrentlyVisible
      })
    }
  }, [isIntersecting])

  chosenPoolsLength.current = chosenPools.length

  const cardLayout = (
    <CardLayout>
      {chosenPools.map((pool) =>
        <>
          <PoolCard key={`poolcard-${pool.sousId}`} pool={pool} account={account} />
          <RoiCalculatorCard key={`roicalculator-${pool.sousId}`} pool={pool} account={account} />
        </>
      )}
    </CardLayout>
  )

  return (
    <>
      <PageHeader background='transparent'>
        <Flex alignItems="center" flexDirection='column'>
          <StyledImage src="/images/defi-logo.png" alt="Hearn Defi Logo" />
          <Flex alignItems='center' mb='24px'>
            <Heading as="h1" scale="lg" color="primary" textAlign='center' mr='8px'>
              {t('Earn')}
            </Heading>
            {chosenPools[0].apr ? (
              <Heading as="h1" scale="lg" color="primary" textAlign='center' mr='8px'>
                {chosenPools[0].apr.toFixed(2)}
              </Heading>
            ) : (
              <Skeleton width='80px' height='30px' />
            )}
            <Heading as="h1" scale="lg" color="primary" textAlign='center'>
              {t('APR% on your stablecoins $!')}
            </Heading>
          </Flex>
          <WalletMenu />
          <GenericStatsRow pool={chosenPools[0]} />
        </Flex>
      </PageHeader>
      <Page>
        {cardLayout}
        <div ref={observerRef} />
        <Flex justifyContent='center'>
          <ReferralCard account={account} />
        </Flex>
        <Flex alignItems='center' justifyContent='center' mb='36px'>
          <Link external href='https://t.me/hearnfi' aria-label='Telegram' mr='36px'>
            <TelegramIcon width="28px" color={darkColors.text} style={{ cursor: "pointer" }} />
          </Link>
          <Link external href='https://twitter.com/hearnfi' aria-label='Telegram' mr='36px'>
            <TwitterIcon width="28px" color={darkColors.text} style={{ cursor: "pointer" }} />
          </Link>
          <Link external href='https://github.com/hearnfi' aria-label='Telegram'>
            <GithubIcon width="28px" color={darkColors.text} style={{ cursor: "pointer" }} />
          </Link>
        </Flex>
      </Page>
    </>
  )
}

export default Pools
