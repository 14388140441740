import BigNumber from 'bignumber.js'
import { SerializedFarm, DeserializedPool, SerializedPool } from 'state/types'
import { deserializeToken } from 'state/user/hooks/helpers'
import { BIG_ZERO } from 'utils/bigNumber'

type UserData =
  | DeserializedPool['userData']
  | {
    allowance: number | string
    stakingTokenBalance: number | string
    stakedBalance: number | string
    pendingReward: number | string
    rewardEarned: number | string
    commissionEarned: number | string
  }

export const transformUserData = (userData: UserData) => {
  return {
    allowance: userData ? new BigNumber(userData.allowance) : BIG_ZERO,
    stakingTokenBalance: userData ? new BigNumber(userData.stakingTokenBalance) : BIG_ZERO,
    stakedBalance: userData ? new BigNumber(userData.stakedBalance) : BIG_ZERO,
    pendingReward: userData ? new BigNumber(userData.pendingReward) : BIG_ZERO,
    rewardEarned: userData ? new BigNumber(userData.rewardEarned) : BIG_ZERO,
    commissionEarned: userData ? new BigNumber(userData.commissionEarned) : BIG_ZERO
  }
}

export const transformPool = (pool: SerializedPool): DeserializedPool => {
  const { 
    totalStaked, 
    stakingLimit, 
    userData, 
    stakingToken, 
    earningToken, 
    tokenPerBlock, 
    totalCommissions, 
    totalBuyback, 
    totalBoughtback, 
    ...rest 
  } = pool

  return {
    ...rest,
    stakingToken: deserializeToken(stakingToken),
    earningToken: deserializeToken(earningToken),
    userData: transformUserData(userData),
    totalStaked: new BigNumber(totalStaked),
    stakingLimit: new BigNumber(stakingLimit),
    tokenPerBlock: new BigNumber(tokenPerBlock),
    totalCommissions: new BigNumber(totalCommissions),
    totalBuyback: new BigNumber(totalBuyback),
    totalBoughtback: new BigNumber(totalBoughtback),
  }
}

export const getTokenPricesFromFarm = (farms: SerializedFarm[]) => {
  return farms.reduce((prices, farm) => {
    const quoteTokenAddress = farm.quoteToken.address.toLocaleLowerCase()
    const tokenAddress = farm.token.address.toLocaleLowerCase()
    /* eslint-disable no-param-reassign */
    if (!prices[quoteTokenAddress]) {
      prices[quoteTokenAddress] = new BigNumber(farm.quoteTokenPriceBusd).toNumber()
    }
    if (!prices[tokenAddress]) {
      prices[tokenAddress] = new BigNumber(farm.tokenPriceBusd).toNumber()
    }
    /* eslint-enable no-param-reassign */
    return prices
  }, {})
}
