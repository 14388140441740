import React from 'react'
import { CalculateIcon, CardHeader, Flex, Heading, Text, Card } from '@pancakeswap/uikit'
import BigNumber from 'bignumber.js'
import { DeserializedPool } from 'state/types'
import RoiCalculatorSection from 'components/RoiCalculatorSection'
import { useTranslation } from 'contexts/Localization'
import { BIG_ZERO } from 'utils/bigNumber'
import styled from 'styled-components'

const StyledCard = styled(Card)`
  max-width: 352px;
  margin: 0 8px 24px;
  width: 100%;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  position: relative;
  color: ${({ theme }) => theme.colors.secondary};

  ${({ theme }) => theme.mediaQueries.sm} {
    margin: 0 12px 46px;
  }
`

const Wrapper = styled(CardHeader) <{ background?: string }>`
  background: ${({ background, theme }) => theme.colors.gradients[background]};
  border-radius: ${({ theme }) => `${theme.radii.card} ${theme.radii.card} 0 0`};
`

const RoiCardHeader: React.FC = ({ children }) => {
  const background = 'cardHeader'

  return (
    <Wrapper background={background}>
      <Flex alignItems="center" justifyContent="space-between">
        {children}
      </Flex>
    </Wrapper>
  )
}

export const RoiCardHeaderTitle: React.FC<{ title: string; subTitle: string }> = ({
  title,
  subTitle,
}) => {
  return (
    <Flex flexDirection="column" justifyContent="center" style={{ height: '64px' }}>
      <Heading color='body' scale="lg">
        {title}
      </Heading>
      <Text fontSize="14px" color='textSubtle'>
        {subTitle}
      </Text>
    </Flex>
  )
}

const RoiCalculatorCard: React.FC<{ pool: DeserializedPool; account: string }> = ({ pool, account }) => {
  const { t } = useTranslation()
  const {
    stakingToken,
    earningToken,
    isFinished,
    apr,
    rawApr,
    earningTokenPrice,
    stakingTokenPrice,
    userData,
    vaultKey,
  } = pool

  const stakedBalance = userData?.stakedBalance ? new BigNumber(userData.stakedBalance) : BIG_ZERO
  const stakingTokenBalance = userData?.stakingTokenBalance ? new BigNumber(userData.stakingTokenBalance) : BIG_ZERO
  const apyModalLink = stakingToken.address ? `/swap?outputCurrency=${stakingToken.address}` : '/swap'
  const performanceFee = 0

  return (
    <StyledCard>
      <RoiCardHeader>
        <RoiCardHeaderTitle
          title={t('APR Calculator', { asset: earningToken.symbol })}
          subTitle={t('Based on current rates', { symbol: stakingToken.symbol })}
        />
        <CalculateIcon color="primary" width="48px" />
      </RoiCardHeader>
      <RoiCalculatorSection
        earningTokenPrice={earningTokenPrice}
        stakingTokenPrice={stakingTokenPrice}
        apr={vaultKey ? rawApr : apr}
        linkLabel={t('Get %symbol%', { symbol: stakingToken.symbol })}
        linkHref={apyModalLink}
        stakingTokenBalance={stakedBalance.plus(stakingTokenBalance)}
        stakingTokenSymbol={stakingToken.symbol}
        earningTokenSymbol={earningToken.symbol}
        autoCompoundFrequency={0}
        performanceFee={performanceFee}
      />
    </StyledCard>
  )
}

export default RoiCalculatorCard
