import BigNumber from 'bignumber.js'
import poolsConfig from 'config/constants/pools'
import sousChefABI from 'config/abi/sousChef.json'
import cakeABI from 'config/abi/cake.json'
import wbnbABI from 'config/abi/weth.json'
import multicall from 'utils/multicall'
import { getAddress } from 'utils/addressHelpers'
import { BIG_ZERO } from 'utils/bigNumber'
import { getSouschefV2Contract } from 'utils/contractHelpers'
import tokens from 'config/constants/tokens'

export const fetchPoolsBlockLimits = async () => {
  const poolsWithEnd = poolsConfig.filter((p) => p.sousId !== 0)
  const startEndBlockCalls = poolsWithEnd.flatMap((poolConfig) => {
    return [
      {
        address: getAddress(poolConfig.contractAddress),
        name: 'startBlock',
      },
      {
        address: getAddress(poolConfig.contractAddress),
        name: 'bonusEndBlock',
      },
    ]
  })

  const startEndBlockRaw = await multicall(sousChefABI, startEndBlockCalls)

  const startEndBlockResult = startEndBlockRaw.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / 2)

    if (!resultArray[chunkIndex]) {
      // eslint-disable-next-line no-param-reassign
      resultArray[chunkIndex] = [] // start a new chunk
    }

    resultArray[chunkIndex].push(item)

    return resultArray
  }, [])

  return poolsWithEnd.map((cakePoolConfig, index) => {
    const [startBlock, endBlock] = startEndBlockResult[index]
    return {
      sousId: cakePoolConfig.sousId,
      startBlock: new BigNumber(startBlock).toJSON(),
      endBlock: new BigNumber(endBlock).toJSON(),
    }
  })
}

export const fetchPoolsBalanceInformation = async () => {
  const callsStakedSupply = poolsConfig.map((poolConfig) => {
    return {
      address: getAddress(poolConfig.contractAddress),
      name: 'stakedSupply'
    }
  })

  const callsTotalBuyback = poolsConfig.map((poolConfig) => {
    return {
      address: getAddress(poolConfig.contractAddress),
      name: 'totalBuyback'
    }
  })

  const callsTotalBoughtback = poolsConfig.map((poolConfig) => {
    return {
      address: getAddress(poolConfig.contractAddress),
      name: 'totalBoughtback'
    }
  })

  const callsRewardPerBlock = poolsConfig.map((poolConfig) => {
    return {
      address: getAddress(poolConfig.contractAddress),
      name: 'viewEmissionValue'
    }
  })

  const callsReferralCommissions = poolsConfig.map((poolConfig) => {
    return {
      address: getAddress(poolConfig.contractAddress),
      name: 'totalReferralCommissions'
    }
  })

  const poolsTotalStaked = await multicall(sousChefABI, callsStakedSupply)
  const poolsTotalBuyback = await multicall(sousChefABI, callsTotalBuyback)
  const poolsTotalBoughtback = await multicall(sousChefABI, callsTotalBoughtback)
  const poolsRewardPerBlock = await multicall(sousChefABI, callsRewardPerBlock)
  const poolsReferralCommissions = await multicall(sousChefABI, callsReferralCommissions)

  return [
    ...poolsConfig.map((p, index) => ({
      sousId: p.sousId,
      totalStaked: new BigNumber(poolsTotalStaked[index]).toJSON(),
      tokenPerBlock: new BigNumber(poolsRewardPerBlock[index]).toJSON(),
      totalBuyback: new BigNumber(poolsTotalBuyback[index]).toJSON(),
      totalBoughtback: new BigNumber(poolsTotalBoughtback[index]).toJSON(),
      totalCommissions: new BigNumber(poolsReferralCommissions[index]).toJSON(),
    }))
  ]
}

export const fetchPoolStakingLimit = async (sousId: number): Promise<BigNumber> => {
  try {
    const sousContract = getSouschefV2Contract(sousId)
    const stakingLimit = await sousContract.poolLimitPerUser()
    return new BigNumber(stakingLimit.toString())
  } catch (error) {
    return BIG_ZERO
  }
}

export const fetchPoolsStakingLimits = async (
  poolsWithStakingLimit: number[],
): Promise<{ [key: string]: BigNumber }> => {
  const validPools = poolsConfig
    .filter((p) => p.stakingToken.symbol !== 'BNB' && !p.isFinished)
    .filter((p) => !poolsWithStakingLimit.includes(p.sousId))

  // Get the staking limit for each valid pool
  // Note: We cannot batch the calls via multicall because V1 pools do not have "poolLimitPerUser" and will throw an error
  const stakingLimitPromises = validPools.map((validPool) => fetchPoolStakingLimit(validPool.sousId))
  const stakingLimits = await Promise.all(stakingLimitPromises)

  return stakingLimits.reduce((accum, stakingLimit, index) => {
    return {
      ...accum,
      [validPools[index].sousId]: stakingLimit,
    }
  }, {})
}
