import React from 'react'
import { CardBody, CardHeader, Flex, Heading, Text, Card } from '@pancakeswap/uikit'
import styled from 'styled-components'
import ConnectWalletButton from 'components/ConnectWalletButton'

const StyledCard = styled.div`
  margin: 0 8px 24px;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 16px 24px;
  color: ${({ theme }) => theme.colors.secondary};
  border-radius: 8px;
  border: 2px solid ${({ theme }) => theme.colors.cardBorder};

  ${({ theme }) => theme.mediaQueries.sm} {
    margin: 0 12px 46px;
  }
`

const Divider = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.colors.cardBorder};
  height: 1px;
  margin-top: 12px;
  margin-bottom: 8px;
`

const ReferralBody: React.FC = ({ children }) => {
  return (
    <Flex alignItems="center" justifyContent="center">
      {children}
    </Flex>
  )
}

export const ReferralHeader: React.FC<{ title: string }> = ({
  title
}) => {
  return (
    <Flex flexDirection="column">
      <Heading color='body' scale="lg">
        {title}
      </Heading>
      <Divider />
    </Flex>
  )
}

const ReferralCard: React.FC<{ account: string }> = ({ account }) => {

  return (
    <StyledCard>
      <ReferralHeader title='Earn more by referring your friends!' />
      <ReferralBody>
        {account ? (
          <Text>https://app.hearn.fi/?ref={account}</Text>
        ) : (
          <Flex flexDirection='column' alignItems='center'>
            <ConnectWalletButton mt='12px' mb='16px' />
            <Text>Connect your wallet to see the referral link.</Text>
          </Flex>
        )}
      </ReferralBody>
    </StyledCard>
  )
}

export default ReferralCard
