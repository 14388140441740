import React from 'react'
import { Flex, Text, HelpIcon, Skeleton, useTooltip } from '@pancakeswap/uikit'
import { useTranslation } from 'contexts/Localization'
import Balance from 'components/Balance'
import BigNumber from 'bignumber.js'
import { getBalanceNumber } from 'utils/formatBalance'

interface CommissionEarnedRowProps {
  totalEarned: BigNumber,
  tokenSymbol: string,
  tokenDecimals: number
}

const CommissionEarnedRow: React.FC<CommissionEarnedRowProps> = ({ totalEarned, tokenSymbol, tokenDecimals }) => {
  const { t } = useTranslation()

  const {
    targetRef: totalEarnedTargetRef,
    tooltip: totalEarnedTooltip,
    tooltipVisible: totalEarnedTooltipVisible,
  } = useTooltip(t('User earned referral commissions amount in this pool', { symbol: tokenSymbol }), {
    placement: 'bottom',
  })

  return (
    <Flex alignItems="center" justifyContent="space-between" mt={10}>
      <Text color='textSubtle'>{t('Commission earned')}:</Text>
      <Flex alignItems="flex-start">
        {totalEarned && totalEarned.gte(0) ? (
          <>
            <Balance value={getBalanceNumber(totalEarned, tokenDecimals)} decimals={2} unit={` ${tokenSymbol}`} />
            <span ref={totalEarnedTargetRef}>
              <HelpIcon color="textSubtle" width="17px" ml="6px" mt="4px" />
            </span>
          </>
        ) : (
          <Skeleton width="90px" height="26px" />
        )}
        {totalEarnedTooltipVisible && totalEarnedTooltip}
      </Flex>
    </Flex>
  )
}

export default CommissionEarnedRow
