import React from 'react'
import styled from 'styled-components'
import { useTotalSupply, useBurnedBalance } from 'hooks/useTokenBalance'
import { getBalanceNumber, formatLocalisedCompactNumber } from 'utils/formatBalance'
import { usePriceCakeBusd } from 'state/farms/hooks'
import { Flex, Text, Heading, Skeleton } from '@pancakeswap/uikit'
import { useTranslation } from 'contexts/Localization'
import Balance from 'components/Balance'
import tokens from 'config/constants/tokens'
import { DeserializedPool } from 'state/types'

const StyledColumn = styled(Flex) <{ noMobileBorder?: boolean }>`
  flex-direction: column;
  ${({ noMobileBorder, theme }) =>
    noMobileBorder
      ? `${theme.mediaQueries.md} {
           padding: 0 16px;
           border-left: 1px ${theme.colors.inputSecondary} solid;
         }
       `
      : `border-left: 1px ${theme.colors.inputSecondary} solid;
         padding: 0 8px;
         ${theme.mediaQueries.sm} {
           padding: 0 16px;
         }
       `}
`

const Grid = styled.div`
  display: grid;
  grid-gap: 16px 8px;
  margin-top: 24px;
  grid-template-columns: repeat(2, auto);

  ${({ theme }) => theme.mediaQueries.sm} {
    grid-gap: 16px;
  }

  ${({ theme }) => theme.mediaQueries.md} {
    grid-gap: 32px;
    grid-template-columns: repeat(4, auto);
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 32px;
  border-top: 1px solid ${({ theme }) => theme.colors.cardBorder};
  border-bottom: 1px solid ${({ theme }) => theme.colors.cardBorder};
  padding: 28px 8px;
`

const GenericStatsRow: React.FC<{ pool: DeserializedPool }> = ({ pool }) => {
  const { t } = useTranslation()
  const { totalCommissions, tokenPerBlock, totalBoughtback, totalStaked, stakingToken, earningToken } = pool
  const totalSupply = useTotalSupply()
  const burnedBalance = getBalanceNumber(useBurnedBalance(tokens.cake.address))
  const cakeSupply = totalSupply ? getBalanceNumber(totalSupply) - burnedBalance : 0
  const numberTotalStaked = getBalanceNumber(totalStaked, stakingToken.decimals)
  const numberTotalBoughtback = getBalanceNumber(totalBoughtback, earningToken.decimals)
  const numberTotalCommissions = getBalanceNumber(totalCommissions, stakingToken.decimals)
  const emissionsPerBlock = getBalanceNumber(tokenPerBlock, earningToken.decimals)
  const cakePriceBusd = usePriceCakeBusd()
  const mcap = cakePriceBusd.times(cakeSupply)
  const mcapString = formatLocalisedCompactNumber(mcap.toNumber())

  return (
    <Container>
      <Heading as="h1" scale='lg' color='primary' textTransform='uppercase'>
        Protocol Generic Stats
      </Heading>
      <Grid>
        <Flex flexDirection="column">
          <Text color="textSubtle">{t('HEARN price')}</Text>
          {cakePriceBusd ? (
            <Balance decimals={4} lineHeight="1.1" fontSize="24px" bold value={cakePriceBusd.toNumber()} />
          ) : (
            <Skeleton height={24} width={126} my="4px" />
          )}
        </Flex>
        <StyledColumn>
          <Text color="textSubtle">{t('Total supply')}</Text>
          {cakeSupply >= 0 ? (
            <Balance decimals={0} lineHeight="1.1" fontSize="24px" bold value={cakeSupply} />
          ) : (
            <Skeleton height={24} width={126} my="4px" />
          )}
        </StyledColumn>
        <StyledColumn noMobileBorder>
          <Text color="textSubtle">{t('Burned to date')}</Text>
          {burnedBalance >= 0 ? (
            <Balance decimals={0} lineHeight="1.1" fontSize="24px" bold value={burnedBalance} />
          ) : (
            <Skeleton height={24} width={126} my="4px" />
          )}
        </StyledColumn>
        <StyledColumn>
          <Text color="textSubtle">{t('Market cap')}</Text>
          {mcap?.gte(0) && mcapString ? (
            <Heading scale="lg">{t('$%marketCap%', { marketCap: mcapString })}</Heading>
          ) : (
            <Skeleton height={24} width={126} my="4px" />
          )}
        </StyledColumn>
        <Flex flexDirection="column">
          <Text color="textSubtle">{t('BUSD Staked')}</Text>
          {numberTotalStaked >= 0 ? (
            <Balance decimals={2} lineHeight="1.1" fontSize="24px" bold value={numberTotalStaked} />
          ) : (
            <Skeleton height={24} width={126} my="4px" />
          )}
        </Flex>
        <StyledColumn>
          <Text color="textSubtle">{t('Total buyback')}</Text>
          {numberTotalBoughtback >= 0 ? (
            <Balance decimals={2} lineHeight="1.1" fontSize="24px" bold value={numberTotalBoughtback} unit=' HEARN' />
          ) : (
            <Skeleton height={24} width={126} my="4px" />
          )}
        </StyledColumn>
        <StyledColumn noMobileBorder>
          <Text color="textSubtle">{t('Referral Commissions')}</Text>
          {numberTotalCommissions >= 0 ? (
            <Balance decimals={2} lineHeight="1.1" fontSize="24px" bold value={numberTotalCommissions} unit=' BUSD' />
          ) : (
            <Skeleton height={24} width={126} my="4px" />
          )}
        </StyledColumn>
        <StyledColumn>
          <Text color="textSubtle">{t('Current emissions')}</Text>
          {emissionsPerBlock >= 0 ? (
            <Balance decimals={2} lineHeight="1.1" fontSize="24px" bold value={emissionsPerBlock} unit='/block' />
          ) : (
            <Skeleton height={24} width={126} my="4px" />
          )}
        </StyledColumn>
      </Grid>
    </Container>
  )
}

export default GenericStatsRow
