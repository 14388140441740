import { serializeTokens } from './tokens'
import { SerializedFarmConfig } from './types'

const serializedTokens = serializeTokens()

const farms: SerializedFarmConfig[] = [
  {
    pid: 1,
    lpSymbol: 'HEARN-BUSD LP',
    lpAddresses: {
      97: '0x5d3d164388dff2f3c9fa31cf3c072921fea0d70d',
      56: '0x5d3d164388dff2f3c9fa31cf3c072921fea0d70d',
    },
    token: serializedTokens.cake,
    quoteToken: serializedTokens.busd,
  },
  {
    pid: 2,
    lpSymbol: 'BUSD-BNB LP',
    lpAddresses: {
      97: '',
      56: '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16',
    },
    token: serializedTokens.busd,
    quoteToken: serializedTokens.wbnb,
  }
]

export default farms
