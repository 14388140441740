import BigNumber from 'bignumber.js'
import React from 'react'
import { CardBody, Flex, Text, CardRibbon } from '@pancakeswap/uikit'
import ConnectWalletButton from 'components/ConnectWalletButton'
import { useTranslation } from 'contexts/Localization'
import { BIG_ZERO } from 'utils/bigNumber'
import { DeserializedPool } from 'state/types'
import { TokenPairImage } from 'components/TokenImage'
import AprRow from './AprRow'
import { StyledCard } from './StyledCard'
import CardFooter from './CardFooter'
import PoolCardHeader, { PoolCardHeaderTitle } from './PoolCardHeader'
import CardActions from './CardActions'
import TotalStakedRow from './TotalStakedRow'
import TotalEarnedRow from './TotalEarned'
import CommissionEarnedRow from './CommissionEarned'

const PoolCard: React.FC<{ pool: DeserializedPool; account: string }> = ({ pool, account }) => {
  const { sousId, stakingToken, earningToken, totalStaked, isFinished, userData } = pool

  const { t } = useTranslation()
  const stakedBalance = userData?.stakedBalance ? new BigNumber(userData.stakedBalance) : BIG_ZERO
  const accountHasStakedBalance = stakedBalance.gt(0)

  return (
    <StyledCard
      isFinished={isFinished && sousId !== 0}
      ribbon={isFinished && <CardRibbon variantColor="textDisabled" text={t('Finished')} />}
    >
      <PoolCardHeader isStaking={accountHasStakedBalance} isFinished={isFinished && sousId !== 0}>
        <PoolCardHeaderTitle
          title={t('Earn %asset%', { asset: earningToken.symbol })}
          subTitle={t('Stake %symbol%', { symbol: stakingToken.symbol })}
        />
        <TokenPairImage primaryToken={earningToken} secondaryToken={stakingToken} width={64} height={64} />
      </PoolCardHeader>
      <CardBody>
        {/* <AprRow pool={pool} stakedBalance={stakedBalance} /> */}
        {account && <>
          <TotalEarnedRow totalEarned={userData.rewardEarned} tokenSymbol={earningToken.symbol} tokenDecimals={earningToken.decimals} />
          <CommissionEarnedRow totalEarned={userData.commissionEarned} tokenSymbol={stakingToken.symbol} tokenDecimals={stakingToken.decimals} />
        </>}
        <Flex mt="24px" flexDirection="column">
          {account ? (
            <CardActions pool={pool} stakedBalance={stakedBalance} />
          ) : (
            <>
              <Text mb="10px" textTransform="uppercase" fontSize="12px" color="textSubtle" bold>
                {t('Start earning')}
              </Text>
              <ConnectWalletButton />
            </>
          )}
          <Text mt='24px' fontSize='14px' color='textSubtle'>
            {t('The principal deposit (staked %symbol%) cannot be withdrawn, the only return users can get are daily dividends and referral rewards', { symbol: stakingToken.symbol })}
          </Text>
        </Flex>
      </CardBody>
      <CardFooter pool={pool} account={account} />
    </StyledCard>
  )
}

export default PoolCard
