import tokens, { serializeTokens } from './tokens'
import { SerializedPoolConfig, PoolCategory } from './types'

const serializedTokens = serializeTokens()

const pools: SerializedPoolConfig[] = [
  {
    sousId: 1,
    stakingToken: serializedTokens.busd,
    earningToken: serializedTokens.cake,
    contractAddress: {
      97: '0x836c7B8B092EB00Eb3C5aA0411FEc0D30e836cED',
      56: '0x836c7B8B092EB00Eb3C5aA0411FEc0D30e836cED',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 1,
    isFinished: false,
  }
]

export default pools
